<template>
    <b-card title="Full Discount">
  
      <b-tabs>
        <b-tab
          active
          @click="$router.push(`/add-full-discount-country-page/${id}`)"
        >
       
          <template #title>
            <feather-icon icon="PlusIcon" />
            <span>Add Full Discount</span>
          </template>
       
        </b-tab>
        <b-tab @click="$router.push(`/all-full-discount-country-page/${id}`)">
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>  Full Discount</span>
          </template>
     
          <!-- <AllAccreditation></AllAccreditation> -->
        </b-tab>
        <router-view />
      </b-tabs>
  
    </b-card>
  </template>
  
  <script>
  
  import {
    BTabs, BTab, BCard,
  } from 'bootstrap-vue'
 
  import { useRouter } from '@core/utils/utils'
  
  export default {
    setup(props) {
  
      const { route } = useRouter()
      const { id } = route.value.params
      return {
        id,
      }
    },
    components: {
      BCard,
  
      BTabs,
      BTab,
  
  
    },
    data() {
      return {
  
      }
    },
  }
  </script>
  